import React from 'react'
import { GA_TRACKING_ID } from '@/services/Configuration'
import Script from 'next/script'
import { useContentLoaded } from '@/hooks/useContentLoaded'

const GoogleTagManager: React.FC = () => {
  const contentLoaded = useContentLoaded()
  if (!contentLoaded) {
    return null
  }
  return (
    <Script
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GA_TRACKING_ID}');
        `,
      }}
    />
  )
}

export default GoogleTagManager
